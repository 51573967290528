<style>
#datepicker {
  z-index: 1000;
}
.vs-table--tbody-table tr {
  font-family: sans-serif;
  font-size: smaller;
}
</style>
<template>
  <div class="vs-row">
    <template lang="html">
      <div>
        <vx-card actionable class="cardx" title="Utilization Reports">
          <vs-row style="margin-bottom: 1%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
              v-if="getRole('cm4') || getRole('cmca')"
            >
              <v-select
                style="z-index: 1000;  margin-top: 4%; margin: 10px;"
                class="w-full"
                placeholder="City"
                v-model="selected_city"
                :options="cities"
              ></v-select>
              <!-- <v-select
                v-else
                disabled
                style="z-index: 1000;  margin-top: 4%; margin: 10px;"
                class="w-full"
                placeholder="City"
                v-model="selected_city"
                :options="cities"
              ></v-select>-->
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
            >
              <div id="datepicker">
                <date-range-picker
                  ref="picker"
                  :opens="'right'"
                  :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
                  :minDate="null"
                  :maxDate="null"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="true"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="false"
                  v-model="today_date"
                  style="width: 240px"
                  @update="reportGenerateAPI"
                ></date-range-picker>
                <!-- <datepicker placeholder="Select Date" v-model="today_date"></datepicker> -->
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="6"
            ></vs-col>
          </vs-row>
          <vs-table :data="utilization_data">
            <template slot="thead" class="exc">
              <vs-th>Spoc</vs-th>
              <vs-th>Visitors</vs-th>
              <vs-th>M7 Count</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="item.spoc_name" v-for="item in data">
                <vs-td :data="item.spoc_name">
                  <div>{{ item.spoc_name }}</div>
                </vs-td>

                <vs-td>
                  <div>7</div>
                </vs-td>

                <vs-td>
                  <div>17</div>
                </vs-td>
                <template class="expand-user" slot="expand">
                  <vs-row>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-w="12"
                    >
                      <calllog :users="item"></calllog>
                    </vs-col>
                  </vs-row>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </template>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import moment from "moment";
import calllog from "../components/pagesComponents/callLogComponent.vue";
// import Datepicker from "vuejs-datepicker";
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus.js";
import router from "@/router";
import vSelect from "vue-select";
export default {
  data() {
    return {
      itsCM4: false,
      loaded: false,
      today_date: {
        startDate: new Date(),
        endDate: new Date(),
      },
      selected_city: "",
      cities: [],
      all_visit_engagments: [],
      utilization_data: [],
      spocs: [],
      logged_in_user_id: "",
    };
  },
  components: {
    // Datepicker,
    calllog,
    DateRangePicker,
    "v-select": vSelect,
  },
  async mounted() {
    // this.reportGenerateAPI();
    this.getSpoc();
    // this.cm2Redirect();
  },
  watch: {
    selected_city: function() {
      this.reportGenerateAPI();
    },
  },
  methods: {
    getRole(providedRole) {
      if (localStorage.getItem("role") === providedRole) {
        return true;
      } else {
        return false;
      }
    },

    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    showDangerNotification(response) {
      this.$vs.notify({
        title: "Danger",
        text: response.data.message,
        color: "danger",
      });
    },
    showWarningNotification(response) {
      this.$vs.notify({
        title: "Warning",
        text: response.data.message + " in line " + response.data.line,
        color: "warning",
      });
    },
    showErrorNotification(error) {
      this.$vs.notify({
        title: "Danger",
        text: error.response,
        color: "danger",
      });
    },
    // getPercentageByDay(callLogs) {
    //   let time = [];
    //   let ByDayData = [];
    //   callLogs.forEach(log => {
    //     log.updated_at_date = moment.unix(log.time / 1000).format("DD-MM-YYYY");
    //   });
    //   var unique = callLogs
    //     .map(name => {
    //       return {
    //         count: 1,
    //         name: name.updated_at_date
    //       };
    //     })
    //     .reduce((a, b) => {
    //       a[b.name] = (a[b.name] || 0) + b.count;
    //       return a;
    //     }, {});
    //   for (var key in unique) {
    //     if (unique.hasOwnProperty(key)) {
    //       time.push(key);
    //     }
    //   }
    //   time.forEach(time => {
    //     let obj = {
    //       time: time,
    //       data: []
    //     };
    //     callLogs.forEach(log => {
    //       if (log.updated_at_date === time) {
    //         obj.data.push(log);
    //       }
    //     });
    //     ByDayData.push(obj);
    //   });
    //   // console.log(`USER ID => ${callLogs[0].user_id}`);
    //   // console.log(unique);
    //   // console.log(ByDayData);
    //   let percentage = [];
    //   ByDayData.forEach(dayData => {
    //     let value = this.getPercentage(dayData.data);
    //     percentage.push(value);
    //   });
    //   // console.log(percentage);
    //   return percentage;
    // },
    getSpoc() {
      // let url = `${constants.SERVER_API}getSpocs`;
      let url = ``;

      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "cmca"
      ) {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (localStorage.getItem("role") === "exed") {
        url = `${constants.SERVER_API}getExed`;
      }

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then(async (response) => {
          // console.log(response);
          this.logged_in_user_id = response.data.logged_in_user_id;
          this.spocs = response.data.spocs;
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          let unorderedCities = [];
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              unorderedCities.push(key);
            }
          }
          this.cities = this.sortArrayAlphabetically(unorderedCities);
          if (
            localStorage.getItem("role") === "cm4" ||
            localStorage.getItem("role") === "cmca"
          ) {
            this.itsCM4 = true;
            this.selected_city = this.cities[0];
          } else {
            this.spocs.forEach((spoc_data) => {
              if (spoc_data.id === this.logged_in_user_id) {
                this.selected_city = spoc_data.city;
              }
            });
            this.itsCM4 = false;
          }
          this.reportGenerateAPI();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    reportGenerateAPI() {
      this.$vs.loading();
      this.loaded = false;
      let params = {
        spoc_ids: "",
        from_date: this.timetoTimestamp(this.today_date.startDate),
        to_date: this.timetoTimestamp(this.today_date.endDate),
      };
      // console.log(params);
      let spocjoin = [];
      this.spocs.forEach((spoc) => {
        if (spoc.city === this.selected_city) {
          spocjoin.push(spoc.id);
        }
      });
      params.spoc_ids = spocjoin.join();
      axios
        .get(`${constants.SERVER_API}getAllCallLogsForUtilization`, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          params: params,
        })
        .then((response) => {
          // console.log(JSON.stringify(response.data.data));
          console.log("dataygdysa", response.data.data);

          if (response.data.code == 200) {
            this.$vs.loading.close();
            this.utilization_data = response.data.data;
          } else if (response.data.code == 403) {
            this.$vs.loading.close();
            // console.log("not authorised");
            this.showDangerNotification(response);
          } else {
            this.$vs.loading.close();
            // console.log("server error");
            this.showWarningNotification(response);
          }
        })
        .catch((error) => {
          // this.$vs.loading.close();
          // if (error.response) {
          //   this.$vs.loading.close();
          //   // console.log(
          //   //   "error in getAllCallLogsForUtilization",
          //   //   error.response
          //   // );
          //   this.showErrorNotification(error);
          //   if (error.response.status === 401) {
          //     this.$vs.loading.close();
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    getselectedspoc(obj) {
      router.push({ name: "CallLogs", params: { selected_obj: obj } });
    },
  },
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
</style>
